import React, { useState } from "react";
import "../styles/Navbar.css";
import logoImage from "../images/logoImage.png";
import { Link } from "react-router-dom";

function Navbar() {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    // Add any other items you want to remove from local storage
  };

  return (
    <div className="navbar">
      <div className="logo">
        <img src={logoImage} alt="Mrs Oh Wellness" />
      </div>
      <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/booking">Booking</Link>
          </li>
          <li>
            <Link to="/about">About Us</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          {localStorage.getItem("token") ? (
            <li className="dropdown">
              <button onClick={toggleDropdown} className="dropbtn">
                More <span className="arrow">&#9660;</span>
              </button>
              {dropdownOpen && (
                <div className="dropdown-content">
                  <Link to="/login" onClick={handleLogout}>
                    Logout
                  </Link>
                  <Link to="/MyProfile">My Profile</Link>
                </div>
              )}
            </li>
          ) : (
            <li>
              <Link to="/login" className="login-button">
                Login
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;
