import React from "react";
import "../styles/Contact.css";

const Contact = () => {
  return (
    <div className="contact">
      <h1>Contact</h1>
      {/* Add content for contact */}
    </div>
  );
};

export default Contact;
