import React from "react";
import "../styles/About.css";

const About = () => {
  return (
    <div className="about">
      <h1>About Us</h1>
      {/* Add content for about us */}
    </div>
  );
};

export default About;
