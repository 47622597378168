import React, { useState } from "react";

const Profile = () => {
  const [user, setUser] = useState({
    name: "John Doe",
    email: "johndoe@example.com",
    age: 30,
    goals: "Lose weight, Gain muscle, Improve mental health",
    profileImage: "https://via.placeholder.com/150",
  });

  const [editing, setEditing] = useState(false);
  const [newUser, setNewUser] = useState({ ...user });

  const handleChange = (e) => {
    setNewUser({
      ...newUser,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setUser(newUser);
    setEditing(false);
  };

  const handleEditClick = () => {
    setEditing(true);
  };

  return (
    <div className="profile-container">
      <h1>My Profile</h1>
      <img src={user.profileImage} alt="Profile" className="profile-image" />
      {editing ? (
        <form onSubmit={handleSubmit}>
          <label>
            Name:
            <input
              type="text"
              name="name"
              value={newUser.name}
              onChange={handleChange}
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              name="email"
              value={newUser.email}
              onChange={handleChange}
            />
          </label>
          <label>
            Age:
            <input
              type="number"
              name="age"
              value={newUser.age}
              onChange={handleChange}
            />
          </label>
          <label>
            Goals:
            <textarea
              name="goals"
              value={newUser.goals}
              onChange={handleChange}
            ></textarea>
          </label>
          <button type="submit">Save Changes</button>
        </form>
      ) : (
        <div className="profile-details">
          <p>
            <strong>Name:</strong> {user.name}
          </p>
          <p>
            <strong>Email:</strong> {user.email}
          </p>
          <p>
            <strong>Age:</strong> {user.age}
          </p>
          <p>
            <strong>Goals:</strong> {user.goals}
          </p>
          <button onClick={handleEditClick}>Edit Profile</button>
        </div>
      )}
    </div>
  );
};

export default Profile;
