import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Home.css";
import gutImage from "../images/home1.png";
import Icon1 from "../images/Icon1.png";
import Icon2 from "../images/Icon2.png";
import Icon3 from "../images/Icon3.png";
import Icon4 from "../images/Icon4.png";
import Home2 from "../images/home2.png";

function Home() {
  const navigate = useNavigate(); // Initialize navigate

  // Define the onClick handler
  const handleBookingClick = () => {
    navigate("/booking"); // Navigate to the Booking page
  };

  return (
    <div className="home-container">
      <div className="hero-section">
        <div className="hero-text">
          <h1>
            Embark on Your <span>Journey to Wellness</span> Today
          </h1>
          <p>
            Discover the path to a better you with our personalized wellness
            coaching. Let our dedicated coaches guide you towards a happier,
            healthier lifestyle. Book your session now and start your journey to
            wellness!
          </p>
          <button onClick={handleBookingClick} className="cta-button">
            Book a Session Now <span class="arrow"></span>
          </button>
        </div>
        <div className="hero-image">
          <img src={gutImage} alt="Gut Health Benefits" />
        </div>
      </div>

      <div className="Second-Page">
        <h1 className="title">Why Choose Us</h1>
        <p className="subtitle">
          Discover the Benefits of Partnering with Our Wellness Coaching Program
        </p>
      </div>
      <div className="grid-container">
        <div className="column1">
          <div className="row1">
            <img src={Icon1} alt="Expertise and Credibility" className="icon" />
            <h2>Expertise and Credibility</h2>
            <p>
              Benefit from Mrs. Oh's background as a trained health
              practitioner, ensuring professional consultations and credible
              guidance.
            </p>
          </div>
          <div className="row2">
            <img src={Icon3} alt="Inclusive Approach" className="icon" />
            <h2>Inclusive Approach</h2>
            <p>
              Engage with a bilingual website and diverse online workshops,
              promoting inclusivity and accessibility for all individuals.
            </p>
          </div>
        </div>

        <div className="column2">
          <img src={Home2} alt="Wellness Coaching" className="main-image" />
        </div>

        <div className="column3">
          <div className="row1">
            <img src={Icon2} alt="Tailored Support" className="icon" />
            <h2>Tailored Support</h2>
            <p>
              Experience personalized coaching tailored to your unique needs and
              goals, fostering holistic well-being and sustainable lifestyle
              changes.
            </p>
          </div>
          <div className="row2">
            <img
              src={Icon4}
              alt="Transparent and Efficient Service"
              className="icon"
            />
            <h2>Transparent and Efficient Service</h2>
            <p>
              Access quick online workshop bookings and secure payment
              transactions, ensuring a seamless and hassle-free experience for
              our clients.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
