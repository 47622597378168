import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import loginImage from "../images/loginImage.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  });

  const handleLoginClick = (e) => {
    e.preventDefault();
    fetch("http://192.53.168.204:8000/api/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          return res.json().then((data) => {
            throw new Error(data.detail || "Login failed!");
          });
        }
      })
      .then((data) => {
        toast.success(
          "Login successful! Redirecting to home...",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
          //
        );
        //save the token in local storage
        localStorage.setItem("token", data.access);

        //redirect to home page
        setTimeout(() => {
          navigate("/");
        }, 2000); // Redirect after 2 seconds
      })
      .catch((error) => {
        toast.error(error.message || "An error occurred. Please try again.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.error("Error:", error);
      });
  };

  const handleSignupClick = () => {
    navigate("/signup"); // Navigate to the Booking page
  };

  return (
    <div style={styles.container}>
      <div style={styles.loginContainer}>
        <div style={styles.formContainer}>
          <h2 style={styles.title}>Login</h2>
          <form>
            <div style={styles.inputGroup}>
              <label>
                <input
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                  style={styles.input}
                  value={form.email}
                  onChange={handleChange}
                />
              </label>
            </div>
            <div style={styles.inputGroup}>
              <label>
                <input
                  name="password"
                  type="password"
                  placeholder="Enter your password"
                  style={styles.input}
                  value={form.password}
                  onChange={handleChange}
                />
              </label>
            </div>
          </form>
          <ToastContainer />
          <a href="/forgot-password" style={styles.forgotPassword}>
            Forgot password?
          </a>
          <button onClick={handleLoginClick} style={styles.loginButton}>
            Login
          </button>
          <div style={styles.signup}>
            Don't have an account?{" "}
            <a
              onClick={handleSignupClick}
              href="/signup"
              style={styles.signupLink}
            >
              Signup now
            </a>
          </div>
        </div>
        <div style={styles.imageContainer}>
          <div style={styles.overlay}></div>
          <p style={styles.imageText}>
            Every new friend is a new adventure.
            <br />
            let's get connected
          </p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#b4ecf3",
  },
  loginContainer: {
    display: "flex",
    flexDirection: "row",
    width: "900px",
    height: "450px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    overflow: "hidden",
  },
  formContainer: {
    flex: 1,
    padding: "30px",
    backgroundColor: "#fff",
  },
  title: {
    marginBottom: "20px",
    fontSize: "24px",
    color: "#333",
  },
  inputGroup: {
    marginBottom: "20px",
  },
  input: {
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "1px solid #ddd",
  },
  forgotPassword: {
    display: "block",
    marginBottom: "20px",
    color: "#007bff",
    textDecoration: "none",
    fontSize: "14px",
  },
  loginButton: {
    width: "100%",
    padding: "10px",
    backgroundColor: "#4caf50",
    color: "#fff",
    fontSize: "16px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  signup: {
    marginTop: "20px",
    fontSize: "14px",
  },
  signupLink: {
    color: "#007bff",
    textDecoration: "none",
  },
  imageContainer: {
    flex: 1,
    position: "relative",
    backgroundImage: "url(" + loginImage + ")", // Replace with your image URL
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  imageText: {
    position: "absolute",
    bottom: "20px",
    left: "20px",
    color: "#fff",
    fontSize: "18px",
    lineHeight: "1.4",
  },
};

export default Login;
