import React, { useState, useEffect } from "react";
import "../styles/Booking.css";

function Booking() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [workshopType, setWorkshopType] = useState("online");
  const [selectedDate, setSelectedDate] = useState("");
  const [agreedToAlerts, setAgreedToAlerts] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [availableSessions, setAvailableSessions] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  //call profile API when loading booking page

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
      fetch("http://192.53.168.204:8000/api/auth/profile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data);
          setName(data.name);
          setEmail(data.email);
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("Failed to fetch profile, please try again.");
        });
    }
  }, []);

  //fucntion to call the api to list available sessions
  const fetchAvailableSessions = (date) => {
    fetch("http://192.53.168.204:8000/api/sessions/?session_date=" + date, {
      headers: {
        "Content-Type": "application/json",

        "X-TIMEZONE": Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        const availableSessions = data.map((session) => {
          const sessionDate = new Date(session.session_date);
          const date = sessionDate
            .toLocaleDateString([], {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
            .replace(/^\w+,\s/, ""); // Remove the day part

          const startTime = sessionDate.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          });
          const closeTime = new Date(
            sessionDate.getTime() + session.session_duration * 60 * 60 * 1000
          ).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

          return {
            site: session.location_type === 1 ? "ONLINE" : "ONSITE",
            date: date,
            location: session.session_location,
            startTime: startTime,
            closeTime: closeTime,
          };
        });
        setAvailableSessions(availableSessions);
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Failed to fetch available sessions, please try again.");
      });
  };

  // Get today's date in YYYY-MM-DD format
  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  // const availableSessions = [
  //   {
  //     site: "Adelaide Site A",
  //     date: "16 March, 2024",
  //     location: "12 Test St, Adelaide 5012",
  //     startTime: "8:00 AM",
  //     closeTime: "6:00 PM",
  //   },
  // ];

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!agreedToTerms) {
      alert("You must agree to the terms and conditions.");
      return;
    }

    const reservationData = {
      name,
      email,
      workshopType,
      selectedDate,
      agreedToAlerts,
    };

    fetch("http://192.53.168.204:8000/api/bookings", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reservationData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        alert("Booking successful!");
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Booking failed, please try again.");
      });
  };

  //make the function isLoggedIn now

  return (
    <div className="booking-container">
      <form className="booking-form" onSubmit={handleSubmit}>
        <h2>Make reservation for a Workshop Session</h2>

        <label>
          Your Name
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled={isLoggedIn}
            placeholder={!isLoggedIn ? "Enter your name" : ""}
          />
        </label>

        <label>
          Email
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={isLoggedIn}
            placeholder={!isLoggedIn ? "Enter your email" : ""}
          />
        </label>

        <label>
          Preferred Workshop Type
          <div className="workshop-type">
            <label>
              <input
                type="radio"
                value="online"
                checked={workshopType === "online"}
                onChange={() => setWorkshopType("online")}
              />
              Online (Zoom Call)
            </label>
            <label>
              <input
                type="radio"
                value="inPerson"
                checked={workshopType === "inPerson"}
                onChange={() => setWorkshopType("inPerson")}
              />
              In Person (See available workshop sites on the right pane)
            </label>
          </div>
        </label>

        <label>
          Choose a Date
          <input
            type="date"
            value={selectedDate}
            min={getTodayDate()}
            onChange={(e) => [
              setSelectedDate(e.target.value),
              fetchAvailableSessions(e.target.value),
            ]}
            required
          />
        </label>

        <div className="available-session-list">
          {availableSessions.map((session, index) => (
            <div className="session-card" key={index}>
              <h3>{session.site}</h3>
              <p>Event Date: {session.date}</p>
              <p>Location: {session.location}</p>
              <p>Start Time: {session.startTime}</p>
              <p>Close Time: {session.closeTime}</p>
            </div>
          ))}
        </div>

        <label>
          <input
            type="checkbox"
            checked={agreedToAlerts}
            onChange={() => setAgreedToAlerts(!agreedToAlerts)}
          />
          I agree to receive email alerts on future workshops.
        </label>

        <label>
          <input
            type="checkbox"
            checked={agreedToTerms}
            onChange={() => setAgreedToTerms(!agreedToTerms)}
            required
          />
          I agree to the <a href="#">terms and conditions</a>.
        </label>

        <button type="submit">Reserve</button>
      </form>

      <div className="workshops-list">
        {availableSessions.map((workshop, index) => (
          <div className="workshop-card" key={index}>
            <h3>{workshop.site}</h3>
            <p>Event Date: {workshop.date}</p>
            <p>Location: {workshop.location}</p>
            <p>Start Time: {workshop.startTime}</p>
            <p>Close Time: {workshop.closeTime}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Booking;
